<template>
  <div class="tenant-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validRoomNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
      let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("房号格式不对"));
      } else {
        callback();
      }
    }
  };
export default {
  data() {
    let _this = this;
    return {
      rentalStyle: "0", // 0长租 1短租
      opt: {
        title: "退房列表",
        search: [
          {
            key: "rentalStyle",
            label: "租赁模式",
            type: "select-no-filterable",
            value: "0",
            opt: {
              list: [
                {
                  label: "长租",
                  value: "0"
                },
                {
                  label: "短租",
                  value: "1"
                },
                {
                  label: "钟点房",
                  value: "3",
                }
              ]
            }
          },
          {
            key: "realname",
            label: "姓名",
            maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
          },
          //   {
          //     key: "phone",
          //     label: "电话"
          //   },
          {
            key: "apartmentId",
            label: "房源",
            type: "select",
            opt: {
              list: []
            }
          },
          {
            key: "roomNo",
            label: "房号",
            maxlength: 24,
              rules: [{
                validator: validRoomNo,
                trigger: ['blur']
              }]
          },
          {
            key: "state",
            label: "退房状态",
            type: "select-no-filterable",
            opt: {
              list: [
                {
                  label: "全部",
                  value: null
                },
                {
                  label: "申请退房",
                  value: "1"
                },
                {
                  label: "房东同意退房",
                  value: "2"
                },
                {
                  label: "已过期",
                  value: "3"
                },
                { 
                  label: "确认退房", 
                  value: "4" 
                },
                { 
                  label: "已退租", 
                  value: "5" 
                },
                { 
                  label: "已取消", 
                  value: "6" 
                },
              ]
            }
          }
        ],
        columns: [
          {
            label: "姓名",
            key: "realname",
            width: 120,
            on: row => {
              console.log(row);
              let rentalStyle;
                 if(row.rentalStyle == '长租' ){
                        rentalStyle = 0;
                }else if(row.rentalStyle == '短租'){
                        rentalStyle = 1;
                }else if(row.rentalStyle == '钟点房'){
                        rentalStyle = 3;
                }
              _this.$router.push(
                "/main/CheckOut/detail/show/" +
                  row.id +
                  "?rentalStyle=" +
                  rentalStyle
              );
            }
          },
          {
            label: "电话",
            key: "phone"
          },
          {
            label: "租赁模式",
            key: "leaseType"
          },
          {
            label: "房源信息",
            key: "address"
          },
          {
            label: "入住日期",
            key: "rentStartDate"
          },
          {
            label: "申请退房时间",
            key: "quitCreateDate"
          },
          {
            label: "退房日期",
            key: "quitDate"
          },
          {
            label: "退房状态",
            key: "state"
          },
          {
            label: "操作",
            key: "openState",
            type: "action-alt",
            opt: {
              activeText: "同意退房",
              inactiveText: "同意退房",
              on(row, item) {
                 if(row.rentalStyle == '长租' ){
                        row.rentalStyle = 0;
                }else if(row.rentalStyle == '短租'){
                        row.rentalStyle = 1;
                }else if(row.rentalStyle == '长短租'){
                        row.rentalStyle = 2;
                }else if(row.rentalStyle == '钟点房'){
                        row.rentalStyle = 3;
                }
                row.checkoutSum =
                  row.checkoutSum == "" || row.checkoutSum == undefined
                    ? "0.00"
                    : row.checkoutSum;
                _this.$router.push(
                  "/main/CheckOut/AddDetail/edit/0" +
                    "?realname=" +
                    row.realname +
                    "&address=" +
                    row.address +
                    "&quitDate=" +
                    row.quitDate +
                    "&checkoutSum=" +
                    row.checkoutSum +
                    "&rentalStyle=" +
                    row.rentalStyle +
                    "&roomId=" +
                    row.roomId +
                    "&shortRentId=" +
                    row.shortRentId + 
                    "&leaseId=" +
                    row.leaseId +
                    "&isPlan=" +
                    row.isPlan
                );
              }
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("tenant-list created!!");
    
  },
  activated() {
    this.getApartmentList();

  },
  methods: {
    onGet(opt) {
      for (let index in this.opt.columns) {
        let item = this.opt.columns;
        if (opt.searchForm.rentalStyle == 1 || opt.searchForm.rentalStyle == 3) {
          if (item[index]["key"] == "rentStartDate") {
            item[index]["label"] = "入住时间";
          }
          if (item[index]["key"] == "quitCreateDate") {
            item[index]["label"] = "申请退房时间";
          }
          if (item[index]["key"] == "quitDate") {
            item[index]["label"] = "退房时间";
          }
        } else if (opt.searchForm.rentalStyle == 0) {
          if (item[index]["key"] == "rentStartDate") {
            item[index]["label"] = "入住日期";
          }
          if (item[index]["key"] == "quitCreateDate") {
            item[index]["label"] = "申请退房时间";
          }
          if (item[index]["key"] == "quitDate") {
            item[index]["label"] = "退房日期";
          }
        }
      }
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        rentalStyle: opt.searchForm["rentalStyle"] ? opt.searchForm["rentalStyle"]:"0",
        realname: opt.searchForm["realname"],
        phone: opt.searchForm["phone"],
        apartmentId: opt.searchForm["apartmentId"],
        roomNo: opt.searchForm["roomNo"],
        state: opt.searchForm["state"]
      };
      console.log("opt",opt);
      let url = "";
      if (opt.searchForm["rentalStyle"] == "1") {
        url = "landlord-service/shortRentOrder/listQuit";
      } else {
        url = "tenant-service/leaseroomdetail/listQuit";
      }  
      this.rentalStyle = opt.searchForm["rentalStyle"]
        ? opt.searchForm["rentalStyle"]
        : "0";
      this.post(url, dto, {
        isUseResponse: true
      }).then(res => {
        console.log(res.data);
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          if (this.rentalStyle == 0) {
            item.rentStartDate = this.format(item.rentStartDate, 1);
            item.quitCreateDate = moment(item.quitCreateDate).format(
              "YYYY-MM-DD HH:mm"
            );;
          }
          if (this.rentalStyle == 1) {
            item.rentStartDate = moment(item.rentStartDate).format(
              "YYYY-MM-DD HH:mm"
            );
            item.quitCreateDate = moment(item.quitCreateDate).format(
              "YYYY-MM-DD HH:mm"
            );
          }
          item.quitDate = moment(item.quitDate).format("YYYY-MM-DD");
          if (item.state == 1) {
            // 只有房东、开启了排房权限的房管员才可操作同意退房
            if(this.getLandlordFlage() || this.getHousekeeperPlanAndQuitFlage()) {
              item.openState = 0;
            }else {
              item.openState = 1;
            }
          } else {
            item.openState = 1;
          }
          item.phone = item.phone
            ? item.phone.substring(0, 3) +
              "****" +
              item.phone.substring(7, item.phone.length)
            : "";
          item.state = [
            "申请退房",
            "房东同意退房",
            "已过期",
            "确认退房",
            "已退租",
            "已取消",
          ][item.state - 1];
          item.rentalStyle = ["长租", "短租", "长短租","钟点房"][item.rentalStyle];
          item.leaseType = ["短租", "长租", "钟点房","PMS排房"][item.leaseType-1]
        }
        opt.cb(res.data);
      });
    },

    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"]
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.id] = data.name;
          });
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.opt.search[2]["opt"]["list"] = res;
        }
      );
    }
  }
};
</script>
<style lang="scss">
.alertTilte {
  .ul_div {
    display: flex;
    line-height: 40px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #666666;
    margin-bottom: 10px;
  }
  .ul_li_one {
    width: 200px;
    height: 40px;
    text-align: right;
  }
  .ul_li_scond {
    width: 160px;
    height: 40px;
    text-align: center;
  }
  .ul_li_thrwe {
    width: 465px;
    text-align: left;
    .img_div {
      width: 70px;
      height: 70px;
      float: left;
      border: 1px solid #dcdfe6;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .textarea {
    width: 460px;
    height: 80px;
    border-color: #dcdfe6;
    color: #040405;
    resize: none;
  }
}
</style>